.bigIcon {
  border-radius: 20%;
  position: relative;
  left: 37%;
  width: 26%;
}

.informationItem {
  position: relative;
  align-content: center;
  margin-top: 2%;
}

.homeIcon {
  font-size: 30px;
}
