i {
  margin-right: 10px;
}

.navbar-logo {
  padding: 15px;
  color: #fff;
}

.navbar-mainbg {
  background-color: #000000;
  color: #fdf7f7;
  padding: 0px;
}

.Nav_link:link {
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-decoration: none;
}
.Nav_link:visited {
  color: #fff;
}
.Nav_link:hover {
  color: yellow;
}
.Nav_link:active {
  color: rgb(2, 112, 8);
}

.navBarActive {
  border-bottom: 0.4rem solid rgb(217, 221, 221);
  background-color: rgb(2, 112, 8);
  color: rgb(2, 112, 8);
}

#navbarSupportedContent {
  overflow: hidden;
  position: relative;
}
